<template>
  <div class="content-section body-wpr">
    <div class="body-wp">
      <div class="statistic-area">
        <div class="statistic-area-wrap">
          <img
            src="@/assets/image/statistic.svg"
            alt="statistic"
            class="statistic-img"
            style="margin-right: 14px"
          />
          <div>
            <p class="statistic-title">전체 결제오류</p>
            <p class="statistic-count">{{ allError }}건</p>
          </div>
        </div>
        <div class="statistic-area-wrap">
          <img
            src="@/assets/image/statistic.svg"
            alt="statistic"
            class="statistic-img"
            style="margin-right: 14px"
          />
          <div>
            <p class="statistic-title">재결제오류</p>
            <p class="statistic-count">{{ reError }}건</p>
          </div>
        </div>
      </div>
      <div class="search-area mb-16">
        <!-- 검색설정 -->
        <div class="search_wrap py-16">
          <!-- 조회기간 -->
          <div class="d-flex">
            <div class="search_title fb-16">조회기간</div>
            <div class="search_list pt-16">
              <div class="d-flex align-items-center">
                <input-radio v-model="selectRadio" val-nm="PERIOD"
                  >기간별</input-radio
                >
                <div class="mr-8">
                  <button
                    class="tiny btn-w"
                    :class="{ 'select-button': selectedButton === 'ALL' }"
                    @click="selectRange('ALL')"
                  >
                    전체
                  </button>
                  <button
                    class="tiny btn-w"
                    :class="{ 'select-button': selectedButton === 'TODAY' }"
                    @click="selectRange('TODAY')"
                  >
                    오늘
                  </button>
                  <button
                    class="tiny btn-w"
                    :class="{ 'select-button': selectedButton === 'WEEK' }"
                    @click="selectRange('WEEK')"
                  >
                    1주일
                  </button>
                  <button
                    class="tiny btn-w"
                    :class="{ 'select-button': selectedButton === 'MONTH' }"
                    @click="selectRange('MONTH')"
                  >
                    1개월
                  </button>
                  <button
                    class="tiny btn-w"
                    :class="{
                      'select-button': selectedButton === 'THREEMONTH',
                    }"
                    @click="selectRange('THREEMONTH')"
                  >
                    3개월
                  </button>
                  <button
                    class="tiny btn-w"
                    :class="{ 'select-button': selectedButton === 'FREE' }"
                    @click="selectRange('FREE')"
                  >
                    직접 설정
                  </button>
                </div>
                <div class="align-items-center">
                  <v-date-picker
                    ref="datepicker"
                    v-model="range"
                    :popover="popover"
                    is-range
                  >
                    <template v-slot="{ inputEvents }">
                      <div class="flex justify-center items-center">
                        <input
                          :value="formatDate(range.start)"
                          v-on="inputEvents.start"
                          :disabled="popover.visibility === 'hidden'"
                          class="my-8 pa-2"
                          style="height: 36px; padding-left: 10px; border: none"
                        />
                        <span class="px-8">~</span>
                        <input
                          :value="formatDate(range.end)"
                          v-on="inputEvents.end"
                          :disabled="popover.visibility === 'hidden'"
                          class="my-8"
                          style="height: 36px; padding-left: 10px; border: none"
                        />
                      </div>
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="d-flex align-items-center pb-8">
                <input-radio v-model="selectRadio" val-nm="MONTH"
                  >월별</input-radio
                >
                <div class="align-items-center">
                  <select-box
                    :opt-nm="YEAR_LIST"
                    v-model="selectedYear"
                    class="mr-16"
                    style="width: 120px; text-align: left"
                  />
                  <select-box
                    :opt-nm="MONTH_LIST"
                    v-model="selectedMonth"
                    style="width: 120px; text-align: left"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 상세조건 -->
          <div class="d-flex">
            <div class="search_title fb-16">상세조건</div>
            <div class="search_list align-items-center d-flex py-16">
              <div>
                <select-box
                  :opt-nm="SELT_PAYMENT_ERROR"
                  v-model="params.searchType"
                  class="mr-16"
                  style="width: 150px; text-align: left"
                />
                <!-- 유형 -->
                <select-box
                  :opt-nm="SELT_ALL_PAYMENT_ERROR_TYPE"
                  v-model="params.searchString"
                  style="width: 150px; text-align: left"
                  v-if="params.searchType === 'TYPE'"
                />
                <input
                  class="my-8"
                  style="height: 36px; padding-left: 10px"
                  v-model="params.searchString"
                  v-if="
                    params.searchType === 'GROUP' ||
                    params.searchType === 'MEMBER' ||
                    params.searchType === 'SERVICE' ||
                    params.searchType === 'PAYMENT'
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="search_button d-flex justify-content-center">
          <button type="submit" class="small btn-m" @click="getData">
            검색
          </button>
        </div>
      </div>
      <div class="sort-div">
        <span class="sort-count"
          >내역
          <span style="color: #2ccdc3; font-weight: 700">{{ totCnt }}</span>
          건</span
        >
        <div class="d-flex">
          <select-box
            :opt-nm="SELT_ORD"
            v-model="params.order"
            class="mr-16"
            style="width: 120px; text-align: left"
          />
          <select-box
            :opt-nm="SELT_CNT"
            v-model="params.numOfData"
            style="width: 120px; text-align: left"
          />
        </div>
      </div>
      <table class="search-table">
        <thead>
          <colgroup>
            <col v-for="(col, colIdx) in tblCol" :key="colIdx" :width="col" />
          </colgroup>
        </thead>
        <tr>
          <th v-for="(fld, fldIdx) in tblFld" :key="fldIdx">{{ fld }}</th>
        </tr>
        <tr v-for="(row, rowIdx) in tblRow" :key="rowIdx">
          <td>{{ row.paymentDate }}</td>
          <td>
            <span
              :class="`member-link ${row.errorCount > 1 ? 'pay-error' : ''}`"
              @click="openReceipt(row)"
              >{{ row.paymentEsntlId }}</span
            >
          </td>
          <td>
            <span
              :class="`member-link ${row.errorCount > 1 ? 'pay-error' : ''}`"
              @click="openPaymentGroup(row)"
              >{{ row.memberEsntlId }}</span
            >
          </td>
          <td>
            <span class="member-link" @click="openPaymentNumber(row)">{{
              row.serviceEsntlId
            }}</span>
          </td>
          <td>
            <span>{{ getType(row.type) }}</span>
          </td>
          <td>{{ row.currentNumber }}/{{ row.maxNumber }}개월</td>
          <td>{{ row.customerName }}</td>
          <td>{{ formatPrice(row.price) }}</td>
        </tr>
      </table>
      <div class="div-search-null" v-if="tblRow.length === 0">
        검색 데이터가 없습니다.
      </div>
      <pagination class="mt-16" @click="onChangePage" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "PaymentErrorList",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** data ***/
  data() {
    return {
      selectRadio: "PERIOD",
      selectedButton: "ALL",
      selectedYear: "2022",
      selectedMonth: "01",
      params: {
        page: 1,
        numOfData: 10,
        order: "DESC",
        startDate: "",
        endDate: "",
        searchType: "",
        searchString: "",
      },
      allError: 0,
      reError: 0,
      totCnt: 0,
      range: {
        start: "",
        end: "",
      },
      popover: {
        visibility: "hidden",
      },
      tblCol: ["15%", "8%", "8%", "20%", "10%", "8%", "8%", "8%"],
      tblFld: [
        "결제일시",
        "결제번호",
        "결제그룹번호",
        "항목번호",
        "유형",
        "회차",
        "결제자",
        "결제금액",
      ],
      tblRow: [],
    };
  },

  /*** created hook ***/
  async created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.setParams();
    }
    await this.getData();
  },

  mounted() {
    if (this.selectRadio === "PERIOD") {
      this.selectRange(this.selectedButton);
    }
  },

  /*** computed ***/
  computed: {
    ...mapGetters([
      "G_PAGE",
      "G_ORDER",
      "G_LSTCNT",
      "G_FOLDER",
      "G_FOLDER_ID",
      "G_FOLDER_ARR",
      "G_IS_CONFIRM",
    ]),
    cls() {
      let cls = "";
      cls += "input-rel ";
      cls += this.wpCls || "";
      return cls;
    },
  },

  /*** watch ***/
  watch: {
    G_IS_CONFIRM(val) {
      if (val) {
        this.getData();
      }
    },
  },

  /*** methods ***/
  methods: {
    setParams() {
      Object.keys(this.$route.query).forEach((key) => {
        if (
          key === "selectRadio" ||
          key === "selectedButton" ||
          key === "selectedYear" ||
          key === "selectedMonth"
        ) {
          this[key] = this.$route.query[key];
        } else {
          this.params[key] = this.$route.query[key];
        }
      });
    },
    // 데이터 가져오기 공통모듈
    async getData() {
      if (this.selectRadio === "PERIOD") {
        this.params.startDate = this.range.start
          ? this.$moment(this.range.start).format("YYYY-MM-DD")
          : "";
        this.params.endDate = this.range.end
          ? this.$moment(this.range.end).format("YYYY-MM-DD")
          : "";
      } else {
        const date = `${this.selectedYear}-${this.selectedMonth}-01`;
        this.params.startDate = this.$moment(date).format("YYYY-MM-DD");
        this.params.endDate = this.$moment(date)
          .add(1, "months")
          .add(-1, "days")
          .format("YYYY-MM-DD");
      }
      const rs = await this.$axios({
        url: "/partner/payment/error/data",
        params: this.params,
      });
      if (rs.result === "SUC") {
        this.tblRow = rs.resultList;
        this.totCnt = rs.totcnt;
        this.allError = rs.allError;
        this.reError = rs.reError;
        await this.$store.dispatch("A_PAGE", rs.page);
        await this.$store.dispatch("A_TOTCNT", rs.totcnt);
        this.replaceRoute();
      }
    },

    replaceRoute() {
      const selectedParams =
        this.selectRadio === "PERIOD"
          ? {
              selectRadio: this.selectRadio,
              selectedButton: this.selectedButton,
            }
          : {
              selectRadio: this.selectRadio,
              selectedYear: this.selectedYear,
              selectedMonth: this.selectedMonth,
            };
      const queryParams = { ...this.params, ...selectedParams };
      if (this.makeQuery(queryParams) !== this.makeQuery(this.$route.query)) {
        this.$router.replace({
          path: `/payment/error/list?${this.makeQuery(queryParams)}`,
          meta: { id: "3-1-2" },
        });
      }
    },

    makeQuery(json) {
      const URI = encodeURIComponent;
      return Object.entries(json)
        .filter((item) => item[1].toString().length > 0)
        .map(([key, value]) => `${URI(key)}=${URI(value)}`)
        .join("&");
    },

    selectRange(type) {
      this.popover.visibility = "hidden";
      this.selectedButton = type;
      switch (type) {
        case "ALL":
          this.range.start = "";
          this.range.end = "";
          break;
        case "TODAY":
          this.range.start = this.$moment().toDate();
          this.range.end = this.$moment().toDate();
          this.$refs["datepicker"].updateValue(this.range, "");
          break;
        case "WEEK":
          this.range.start = this.$moment().add(-7, "days").toDate();
          this.range.end = this.$moment().toDate();
          this.$refs["datepicker"].updateValue(this.range, "");
          break;
        case "MONTH":
          this.range.start = this.$moment().add(-1, "months").toDate();
          this.range.end = this.$moment().toDate();
          this.$refs["datepicker"].updateValue(this.range, "");
          break;
        case "THREEMONTH":
          this.range.start = this.$moment().add(-3, "months").toDate();
          this.range.end = this.$moment().toDate();
          this.$refs["datepicker"].updateValue(this.range, "");
          break;
        case "FREE":
          this.popover.visibility = "hover";
          break;
      }
    },

    formatDate(date) {
      return date ? this.$moment(date).format("YYYY.MM.DD") : "";
    },

    getGender(type) {
      switch (type) {
        case "M":
          return "남";
        case "F":
          return "여";
      }
    },

    getType(type) {
      switch (type) {
        case "E":
          return "결제오류";
        case "S":
          return "이용정지";
        case "M":
          return "한도초과";
      }
    },

    formatPrice(price) {
      return price
        ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "-";
    },

    formatPhone(num) {
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },

    // 페이지 이동
    async onChangePage(value) {
      this.params = { ...this.params, page: value };
      this.pager = { ...this.pager, page: value };
      await this.getData();
    },

    async openReceipt(row) {
      // 결제번호 클릭 시
      await this.$store.dispatch("A_MEMBER_CODE", row.paymentEsntlId);
      await this.$openReceipt({}, this);
    },

    async openPaymentType(row) {
      await this.$store.dispatch("A_MEMBER_CODE", row.paymentEsntlId);
      await this.$openPaymentType({}, this);
    },

    async openPaymentGroup(row) {
      // 결제그룹번호 클릭 시
      await this.$store.dispatch("A_MEMBER_CODE", row.memberEsntlId);
      await this.$openPaymentGroup({}, this);
    },

    async openPaymentNumber(row) {
      // 항목번호 클릭 시
      await this.$store.dispatch("A_MEMBER_CODE", row.serviceEsntlId);
      await this.$openPaymentNumber({}, this);
    },
  },
};
</script>

<style scoped lang="scss">
.statistic-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 96px;
  padding-left: 38px;
}
.statistic-area-wrap {
  display: flex;
  align-items: center;
  margin-right: 100px;
  cursor: pointer;
}
.statistic-img {
  width: 36px;
  height: 36px;
}
.statistic-title {
  font-weight: 400;
  font-size: 14px;
  color: #515466;
}
.statistic-count {
  font-weight: 500;
  font-size: 16px;
  color: #2ccdc3;
}
.sort-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.sort-count {
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
.select-button {
  color: #ffffff;
  background: #696c80;
}
.div-search-null {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray20);
}
.status-term {
  font-weight: 500;
  color: #12131a;
  font-size: 14px;
}
.status-used {
  font-weight: 400;
  color: #12131a;
  font-size: 14px;
}
.status-end {
  font-weight: 500;
  color: #f1431d;
  font-size: 14px;
}
.member-link {
  text-decoration: underline;
  cursor: pointer;
}
.pay-error {
  color: #f1431d;
}
</style>
